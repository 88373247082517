<template>
  <div v-if="day" class="v-stack gap-3 h-stretch">
    <a class="heading-title-1">Info</a>
    <div class="card light">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left">Project</label>
          <div class="text-left">
            {{
              day.project.client.name +
              " - " +
              day.project.title +
              " " +
              day.project.subtitle
            }}
          </div>
          <label class="text-left">Date</label>
          <div class="text-left">
            {{ formatDate(day.startDate, "D. M. YYYY") }}
          </div>
          <div class="text-left">
            {{ formatDate(day.startDate, "HH:mm") }} -
            {{ formatDate(day.endDate, "HH:mm") }}
          </div>
          <label class="text-left">Address</label>
          <textarea disabled v-model="day.address"></textarea>
          <label class="text-left">Description</label>
          <textarea disabled v-model="day.description"></textarea>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button @click="$router.push('/productiondays/edit/' + id)" class="edit">
        Edit
      </button>
    </div>
    <a class="heading-title-1">Crew</a>
    <Table
      v-if="day"
      :items="day.staff"
      :defaultColumn="0"
      :limit="5"
      css="200px 140px 1fr"
      :expandable="true"
      v-on:rowSelected="
        $router.push('/projects/staff/' + $event._id + '?roles=production')
      "
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['user', 'fullName'],
        },
        {
          name: 'Roles',
          sort: 'alphabet',
          path: ['roles'],
          formatType: 'array',
        },
        {
          name: 'Description',
          sort: 'alphabet',
          path: ['comment'],
        },
      ]"
    >
    </Table>
    <div class="h-stack h-end">
      <button
        class="add"
        @click="$router.push('/productiondays/staff/add/' + day._id)"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import Table from "@/components/Table.vue";

export default {
  props: ["id"],
  data() {
    return {
      day: null,
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getRecordingDay"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.getRecordingDay(this.id)
      .then((day) => {
        this.day = day;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.staff-row {
  grid-auto-columns: 1fr 1fr 1fr !important;
  gap: 10px;
}
</style>