var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.day)?_c('div',{staticClass:"v-stack gap-3 h-stretch"},[_c('a',{staticClass:"heading-title-1"},[_vm._v("Info")]),_c('div',{staticClass:"card light"},[_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"v-stack gap-3 h-stretch"},[_c('label',{staticClass:"text-left"},[_vm._v("Project")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.day.project.client.name + " - " + _vm.day.project.title + " " + _vm.day.project.subtitle)+" ")]),_c('label',{staticClass:"text-left"},[_vm._v("Date")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.day.startDate, "D. M. YYYY"))+" ")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.day.startDate, "HH:mm"))+" - "+_vm._s(_vm.formatDate(_vm.day.endDate, "HH:mm"))+" ")]),_c('label',{staticClass:"text-left"},[_vm._v("Address")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.day.address),expression:"day.address"}],attrs:{"disabled":""},domProps:{"value":(_vm.day.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.day, "address", $event.target.value)}}}),_c('label',{staticClass:"text-left"},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.day.description),expression:"day.description"}],attrs:{"disabled":""},domProps:{"value":(_vm.day.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.day, "description", $event.target.value)}}})])])]),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.$router.push('/productiondays/edit/' + _vm.id)}}},[_vm._v(" Edit ")])]),_c('a',{staticClass:"heading-title-1"},[_vm._v("Crew")]),(_vm.day)?_c('Table',{attrs:{"items":_vm.day.staff,"defaultColumn":0,"limit":5,"css":"200px 140px 1fr","expandable":true,"columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['user', 'fullName'],
      },
      {
        name: 'Roles',
        sort: 'alphabet',
        path: ['roles'],
        formatType: 'array',
      },
      {
        name: 'Description',
        sort: 'alphabet',
        path: ['comment'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/projects/staff/' + $event._id + '?roles=production')}}}):_vm._e(),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/productiondays/staff/add/' + _vm.day._id)}}},[_vm._v(" Add ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }